import React from "react";
import { useTranslation } from "react-i18next";
import usaIcon from "assets/usa.svg";
import brazilIcon from "assets/brazil.svg";

export const SwitchLanguage = ({ classExtra }: SwitchLanguageProps) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className={`flex flex-row items-center justify-between gap-2 ${classExtra}`}
    >
      <button
        className={`border-0 bg-0 w-8 h-8 cursor-pointer transition-all duration-500 ${
          i18n.language === "en" ? "opacity-50" : ""
        }`}
        onClick={() => changeLanguage("en")}
      >
        <img src={usaIcon} alt="USA" />
      </button>
      <button
        className={`border-0 bg-0 w-8 h-8 cursor-pointer transition-all duration-500 ${
          i18n.language === "pt" ? "opacity-50" : ""
        }`}
        onClick={() => changeLanguage("pt")}
      >
        <img src={brazilIcon} alt="Brazil" />
      </button>
    </div>
  );
};

type SwitchLanguageProps = {
  classExtra?: string;
};
