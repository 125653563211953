import { SwitchLanguage } from "components/SwitchLanguage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user:smart-dc");
    navigate("/");
  };

  return (
    <nav className="flex flex-row justify-between items-center p-4 bg-blue-950">
      <h1 className="text-2xl text-white font-extrabold">
        Smart <span className="text-yellow-400">DC</span>
      </h1>

      <div className="flex flex-row items-center justify-end gap-5">
        <SwitchLanguage />
        <button
          onClick={handleLogout}
          className="text-yellow-400 font-extrabold px-4 py-2 rounded-lg bg-none hover:brightness-75 transition-all duration-500"
        >
          {t("logout")}
        </button>
      </div>
    </nav>
  );
};
