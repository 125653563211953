import informationsItemsImage from "assets/informations-items.svg";
import d1ItemsImage from "assets/d1-items.svg";
import d2ItemsImage from "assets/d2-items.svg";
import d3ItemsImage from "assets/d3-items.svg";
import d4ItemsImage from "assets/d4-items.svg";

export const dataItems = [
  [
    { title: "model", value: "", type: "select", name: "modelo", unit: "" },
    { title: "size", value: "", type: "select", name: "tamanho", unit: "" },
    {
      title: "serialNumber",
      value: "",
      type: "number",
      name: "numero_de_serie",
      unit: "",
    },
    {
      title: "ambientTemperature",
      value: "",
      type: "number",
      name: "temperatura_ambiente",
      unit: "⁰C",
    },
    {
      title: "climatizedPiece",
      value: "",
      type: "radio",
      name: "peca_climatizada",
      unit: "",
    },
  ],
  [
    {
      title: "deviation",
      value: "",
      type: "number",
      name: "desvio_raio_do_esferico",
      unit: "mm",
    },
    {
      title: "outOfTolerance",
      value: "",
      type: "number",
      name: "fora_de_tolerancia_raio_do_esferico",
      unit: "mm",
    },
  ],
  [
    {
      title: "deviation",
      value: "",
      type: "number",
      name: "desvio_altura_da_divisoria",
      unit: "mm",
    },
    {
      title: "outOfTolerance",
      value: "",
      type: "number",
      name: "fora_de_tolerancia_altura_da_divisoria",
      unit: "mm",
    },
  ],
  [
    {
      title: "deviation",
      value: "",
      type: "number",
      name: "desvio_comprimento_face_b",
      unit: "mm",
    },
    {
      title: "outOfTolerance",
      value: "",
      type: "number",
      name: "fora_de_tolerancia_comprimento_face_b",
      unit: "mm",
    },
  ],
  [
    {
      title: "deviation",
      value: "",
      type: "number",
      name: "desvio_comprimento_face_oposta",
      unit: "mm",
    },
    {
      title: "outOfTolerance",
      value: "",
      type: "number",
      name: "fora_de_tolerancia_comprimento_face_oposta",
      unit: "mm",
    },
  ],
];

export const stepsTitles = [
  "pieceInfo",
  "d1Title",
  "d2Title",
  "d3Title",
  "d4Title",
];

export const imagesSteps = [
  informationsItemsImage,
  d1ItemsImage,
  d2ItemsImage,
  d3ItemsImage,
  d4ItemsImage,
];

export const optionsTypeOption = {
  modelo: [
    {
      value: "1",
      label: "DIN 31690 - ZR",
    },
    {
      value: "2",
      label: "DIN 31693 - ZF",
    },
    {
      value: "3",
      label: "DIN 31694 - ZM",
    },
  ],
  tamanho: [
    {
      value: "7",
      label: "7",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "28",
      label: "28",
    },
  ],
};

export type OptionsTypeOptionKeys = keyof typeof optionsTypeOption;
