type DecisionTreeParamsD1 = {
  d1: number;
  d2: number;
  temperature: number;
};

type DecisionTreeParamsD2 = {
  d2: number;
  ootd2: number;
  climatized: boolean;
};

type DecisionTreeParamsD3 = {
  d3: number;
  climatized: boolean;
};

type DecisionTreeParamsD4 = {
  d4: number;
  ootd4: number;
};

export function getDecisionTreeResultD1(params: DecisionTreeParamsD1): string {
  const { d1, d2, temperature } = params;

  if (d1 <= 0.025) {
    if (d1 <= 0.007) {
      if (temperature <= 23.5) {
        return "0,00";
      } else {
        return "0,002";
      }
    } else {
      return "0,00";
    }
  } else {
    if (d2 <= 0.004) {
      return "-0,001";
    } else {
      return d1 <= 0.031 ? "-0,004" : "-0,005";
    }
  }
}

export function getDecisionTreeResultD2(params: DecisionTreeParamsD2): string {
  const { d2, ootd2, climatized } = params;

  if (d2 <= 0.003) {
    if (ootd2 <= -0.018) {
      return "-0,003";
    } else {
      if (d2 <= -0.016) {
        return "-0,001";
      } else {
        if (d2 <= 0.001) {
          return "0,00";
        } else {
          return "0,00";
        }
      }
    }
  } else {
    if (climatized) {
      return "0,01";
    } else {
      if (ootd2 <= 0.008) {
        return "0,004";
      } else {
        return "0,01";
      }
    }
  }
}

export function getDecisionTreeResultD3(params: DecisionTreeParamsD3): string {
  const { d3, climatized } = params;
  if (d3 <= -0.023) {
    return "0,014";
  } else {
    if (d3 <= 0.019) {
      if (d3 <= -0.012) {
        return "0,002";
      } else {
        if (climatized) {
          return "0,00";
        } else {
          if (d3 <= 0.002) {
            return "0,00";
          } else {
            if (d3 <= 0.007) {
              return "-0,003";
            } else {
              return "0,00";
            }
          }
        }
      }
    } else {
      if (d3 <= 0.023) {
        return "-0,003";
      } else {
        return "-0,003";
      }
    }
  }
}

export function getDecisionTreeResultD4(params: DecisionTreeParamsD4): string {
  const { d4, ootd4 } = params;

  if (ootd4 <= 0.012) {
    if (d4 <= 0.019) {
      return "0,00";
    } else {
      return "-0,009";
    }
  } else {
    return "-0,023";
  }
}
