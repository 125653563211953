import { ChangeEvent, InputHTMLAttributes, useState } from "react";

export const TextWithInput = ({
  value,
  onChange,
  initialState,
  inputType,
  onDoubleClick,
  spanExtraClasses,
  ...props
}: TextWithInputProps) => {
  const [editable, setEditable] = useState(initialState);

  const setEditableValue = () => {
    setEditable(true);
    onDoubleClick();
  };

  return editable ? (
    <input
      step=".001"
      type={inputType}
      onChange={onChange}
      value={value}
      className={`w-full border text-base border-gray-300 p-2 rounded max-h-8`}
      {...props}
    />
  ) : (
    <span className={spanExtraClasses} onDoubleClick={setEditableValue}>
      {value} mm
    </span>
  );
};

type TextWithInputProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string | number;
  initialState: boolean;
  inputType: string;
  onDoubleClick: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  spanExtraClasses?: string;
};
