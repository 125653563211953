import { verifyToken } from "api/auth";

export const verifyIsAuth = async () => {
  const user = JSON.parse(localStorage.getItem("user:smart-dc") as string);

  if (user?.token) {
    const auth = await verifyToken(user.token);
    const userValid = Object.keys(auth).length > 0;
    return userValid;
  } else {
    return false;
  }
};
