import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import ReactLoading from "react-loading";

import emailIcon from "assets/email.svg";
import passwordIcon from "assets/password.svg";
import userIcon from "assets/user.svg";
import eyesCloseIcon from "assets/close-eyes.svg";
import eyesOpenIcon from "assets/open-eyes.svg";
import logo from "assets/logo.svg";

import { verifyIsAuth } from "routes/verifyIsAuth";
import { login, register } from "api/auth";
import { useTranslation } from "react-i18next";
import { SwitchLanguage } from "components/SwitchLanguage";

export const RegisterPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState({ screen: true, button: false });
  const [passwordConfig, setPasswordConfig] = useState({
    icon: eyesCloseIcon,
    type: "password",
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const verifyIsLogged = await verifyIsAuth();
        if (verifyIsLogged) {
          navigate("/dashboard");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading((state) => ({ ...state, screen: false }));
      }
    })();
  }, [navigate, setLoading]);

  const handleClickRegister = () => {
    navigate("/login");
  };

  const handleAlterPasswordVisibility = () => {
    setPasswordConfig({
      icon: passwordConfig.type === "password" ? eyesOpenIcon : eyesCloseIcon,
      type: passwordConfig.type === "password" ? "text" : "password",
    });
  };

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading((state) => ({ ...state, button: true }));

    try {
      const nome = (event.target as HTMLFormElement).nome.value;
      const email = (event.target as HTMLFormElement).email.value;
      const password = (event.target as HTMLFormElement).password.value;

      await register(email, password, nome).then(async () => {
        const user = await login(email, password);
        if (user) {
          localStorage.setItem("user:smart-dc", JSON.stringify(user));
          navigate("/dashboard");
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((state) => ({ ...state, button: false }));
    }
  };

  if (loading.screen)
    return (
      <div className="flex w-full items-center justify-center h-[100vh]">
        <ReactLoading type="spin" color="#172554" height={50} width={50} />
      </div>
    );

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <SwitchLanguage classExtra="absolute top-4 right-4" />
      <div className="flex bg-white shadow-lg rounded-lg overflow-hidden w-[900px]">
        <div className="w-1/3 p-8 bg-white flex flex-col justify-center items-center">
          <img src={logo} alt="Smart DC" className="w-80 mb-0" />
          <h1 className="text-2xl font-bold text-blue-950 mb-2">
            {t("welcome")}
          </h1>
          <p className="text-blue-950 mb-12 text-center">
            {t("ifAlreadyHaveAccount")}
          </p>
          <button
            onClick={handleClickRegister}
            className="bg-white min-w-28 text-blue-950 border border-blue-950 rounded-full py-2 px-4 hover:bg-blue-950 hover:text-white transition duration-300"
          >
            {t("login")}
          </button>
        </div>
        <div className="w-2/3 gap-8 p-8 bg-blue-950 text-white flex flex-col justify-center">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-4xl font-bold mb-4">{t("createAccount")}</h2>
            <p className="mb-4 text-xl">{t("enterDataRegister")}</p>
          </div>
          <form onSubmit={onSubmitForm}>
            <div className="mb-4 relative">
              <img
                src={userIcon}
                alt="Name"
                className="absolute left-3 top-3"
                width={25}
              />
              <label htmlFor="email" className="sr-only">
                {t("name")}
              </label>
              <input
                type="nome"
                id="nome"
                required
                placeholder={t("name")}
                className="w-full p-3 pl-10 rounded-md bg-gray-100 text-gray-900"
              />
            </div>
            <div className="mb-4 relative">
              <img
                src={emailIcon}
                alt="Email"
                className="absolute left-3 top-3"
                width={25}
              />
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                id="email"
                required
                placeholder="Email"
                className="w-full p-3 pl-10 rounded-md bg-gray-100 text-gray-900"
              />
            </div>
            <div className="mb-4 relative">
              <img
                src={passwordIcon}
                alt="Password"
                className="absolute left-3 top-3"
                width={25}
              />
              <label htmlFor="password" className="sr-only">
                {t("password")}
              </label>
              <input
                type={passwordConfig.type}
                id="password"
                placeholder={t("password")}
                required
                className="w-full p-3 pl-10 rounded-md bg-gray-100 text-gray-900"
              />
              <button
                type="button"
                className="absolute right-3 top-3 text-gray-500"
                onClick={handleAlterPasswordVisibility}
              >
                <img src={passwordConfig.icon} alt="Olho" width={25} />
              </button>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-white min-w-36 text-blue-950 mt-7 mx-auto border border-white rounded-full py-2 px-4 hover:bg-blue-950 hover:text-white transition duration-300"
              >
                {loading.button ? (
                  <ReactLoading
                    className="mx-auto"
                    type="spin"
                    color="#172554"
                    height={20}
                    width={20}
                  />
                ) : (
                  t("register")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
